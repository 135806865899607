import React, { useState } from 'react';

import { Box, Menu, Avatar, Typography, Divider, Button, IconButton } from '@mui/material';
import  dropdownData from './data';

import { IconMail } from '@tabler/icons';
import { Stack } from '@mui/system';

// import ProfileImg from 'src/assets/images/profile/user-1.jpg';
// import unlimitedImg from 'src/assets/images/backgrounds/unlimited-bg.png';


const Profile = (props) => {
  const [anchorEl2, setAnchorEl2] = useState(null);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  return (
    <Box>
      <IconButton
        size="large"
        aria-label="show 11 new notifications"
        color="inherit"
        aria-controls="msgs-menu"
        aria-haspopup="true"
        sx={{
          ...(typeof anchorEl2 === 'object' && {
            color: 'primary.main',
          }),
        }}
        onClick={handleClick2}
      >
        <Avatar
          src={""}
          alt={"ProfileImg"}
          sx={{
            width: 35,
            height: 35,
          }}
        />
      </IconButton>
      {/* ------------------------------------------- */}
      {/* Message Dropdown */}
      {/* ------------------------------------------- */}
      <Menu
        id="msgs-menu"
        anchorEl={anchorEl2}
        keepMounted
        open={Boolean(anchorEl2)}
        onClose={handleClose2}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        sx={{
          '& .MuiMenu-paper': {
            width: '360px',
          },
        }}
      >
        
          <Box p={3}>
            <Typography variant="h5">User Profile</Typography>
            <Stack direction="row" py={3} spacing={2} alignItems="center">
              <Avatar src={""} alt={""} sx={{ width: 45, height: 45 }} />
              <Box>
                <Typography
                  variant="subtitle2"
                  color="textSecondary"
                  display="flex"
                  alignItems="center"
                  gap={1}
                >
                  <IconMail width={15} height={15} />
                 {props.userData}
                </Typography>
              </Box>
            </Stack>
            <Divider />
          
            <Box mt={2}>
            
              <Button
                to="/auth/login"
                variant="outlined"
                color="primary"
                component={""}
                fullWidth
                onClick={()=>{props.setIsLoggedIn(false)}}
              >
                Logout
              </Button>
            </Box>
          </Box>
     
      </Menu>
    </Box>
  );
};

export default Profile;
